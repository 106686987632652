@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap');

body{
    background-color: #CCD6F6;
    /* font-family: SourceSansProRegular, Helvetica, sans-serif; */
    /* font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif */
    font-family: 'Didact Gothic', sans-serif;
}

.nav-item{
  font-weight: bold;
}

.myName{
  font-size: 55px;
  font-weight: bold;
  color: #64ffda;
  text-shadow:
  -1px -1px 0 #000, /* Top left outline */
   1px -1px 0 #000, /* Top right outline */
  -1px  1px 0 #000, /* Bottom left outline */
   1px  1px 0 #000; /* Bottom right outline */
}

.header2{
color: #0B192F;
font-size: 40px;
}

.about{
  display: inline-block;
  border-bottom: #0B192F 3px solid;
}

#section1{
    animation: slideDown;
    animation-duration: 2s;
}

@keyframes slideDown {
    from {
        transform: translateY(-300px);
      }
    
      to {
          transform: translateY(0);
      }
  }

.About{
    animation: slideUp;
    animation-duration: 2s;
}

@keyframes slideUp {
    from {
        transform: translateY(600px);
      }
    
      to {
          transform: translateY(0);
      }
  }

 #tech1{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
}

@keyframes slideLeftDelay {
    from {
        transform: translateX(-600px);
      }
    
      to {
          transform: translateX(0);
      }
  }
 #tech2{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
}

 #tech3{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
}

 #tech4{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
}

 #tech5{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
}

 #tech6{
    animation: slideLeftDelay;
    animation-duration: 2s;
    animation-delay: .1s;
    /* visibility: hidden; */
    
}

#tech1:hover{
  color: darkgray;
}
#tech2:hover{
  color: #e34c26;
}
#tech3:hover{
  color: #264de4;
}
#tech4:hover{
  color: #61DBFB;
}
#tech5:hover{
  color: yellow;
}
#tech6:hover{
  color: #563d7c;
}

.card-custom{
  background-color: #0B1930;
}

.card-custom:hover h4 {
  color: #64ffda;
}


#Projects h1{
  color: #CCD6F6;
  display: inline-block;
  border-bottom: #CCD6F6 3px solid;
}

#Experience h1,h3{
  color: #0B192F;
}

#Experience h1{
  display: inline-block;
  border-bottom: #0B192F 3px solid
}

#Contact h1{
  color: #CCD6F6;
  display: inline-block;
  border-bottom: #CCD6F6 3px solid;
}

#Projects, #Contact{
  background-color: #0A1930;
}

#project1:hover, #project2:hover, #project4:hover{
    transform: scale(1.05);
}

#project1 a, #project2 a, #project4 a{
  background-color:#0B192F;
  color: #CCD6F6;
}

#project1:hover a,#project2:hover a, #project4:hover a  {
  color: #64ffda;
}

#section1:hover img{
  border: 3px #0B192F solid;
}

.rjLogo{
    position: relative; 
    top: 48px;
    /* transform: translate(-50%, -50%); */
    animation: spin 3s infinite linear;
  }

@keyframes spin {
     0%{
        transform: translate(-50%,-50%) rotate(0deg);
     }
     100%{
        transform: translate(-50%,-50%) rotate(360deg);
     }
  }

  /* Navbar */
  #nav-about{
    font-size: 25px;
    color: #64ffda;
    text-shadow:
    -1px -1px 0 #000, /* Top left outline */
     1px -1px 0 #000, /* Top right outline */
    -1px  1px 0 #000, /* Bottom left outline */
     1px  1px 0 #000; /* Bottom right outline */
  }

  .nav-link{
    font-size: 25px;
    color: #0B192F;
  }

  .nav-link:hover{
    color: #64ffda;
    text-shadow:
    -1px -1px 0 #000, /* Top left outline */
     1px -1px 0 #000, /* Top right outline */
    -1px  1px 0 #000, /* Bottom left outline */
     1px  1px 0 #000; /* Bottom right outline */
  }

  #linkedIn{
    color: #0B192F;
    text-decoration: 2px underline #64ffda;
  }

  #linkedIn:hover{
    color: #0B192F;
    text-decoration: 2px underline #0B192F;
  }

.projectBtn
{
    background-color: #e6f1ff;
    color: black;
    border: 2px solid #0B192F;
    border-radius: 8px;
    padding: 18px 36px;
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 1px;
    cursor: pointer;
    transition: 500ms ease-in-out;
}

.projectBtn.btn-background-slide:hover {
    box-shadow: inset 400px 0 0 0 #64ffda;
}

/* Experience */


  .btn-learning{
    font-size: 18px;
    background-color: #64ffda;
    border: 2px solid #0B192F;
    color: #0B192F;
  }

  .btn-learning:hover{
    opacity: .75;
  }

 #modal{
    background-color: #64ffda;
    border: 2px solid #0B192F;
  }
 #modal:hover{
  opacity: .75;
  }

  .modal-content{
    background-color: #64ffda;
  }

  .modal-footer button{
    background-color: #0B192F;
    color: #CCD6F6;
    padding: 4px 8px;
    border-radius: 5px; 
  }

  .modal-footer button:hover{
    opacity: .9;
  }


  

  /*Contact*/
  #Contact form input{
    background: transparent;
    padding: 10px 5px;
    border: none;
    border-radius: none;
    border-bottom: 2px solid white;
    color: #CCD6F6;
  }
  ::placeholder{
    color: #8892b0;
  }

  #Contact form input:focus {
    /* outline: none !important;  */
    border:2px solid #64ffda;
    box-shadow: 0 0 10px #64ffda;
   }

   #Contact form textarea:focus {
    /* outline: none !important;  */
    border:2px solid #64ffda;
    box-shadow: 0 0 10px #64ffda;
   }

   #submitBtn{
    background-color: #64ffda;
    color: #0A1930;
   }

  /* Footer */

  .icons{
    color: black;
  }
  .icons:hover{
    color: #64ffda;
  }

  /* Utlities */

  /* @media (min-width: 1400px){
      .container {
        max-width: 2000px;
      }   
  } */

  .About > h3:last-of-type{
    font-size: 20px;
  }

  @media screen and (max-width: 1000px) {
    .About > h3:last-of-type {
        font-size: 16px;
    }
  }

  /* @media screen and (max-width: 500px) {
    .rjLogo{
        width: 200px;
    }
  } */

  @media screen and (max-width: 1259px) {
   
    #section1{
        margin-top: 50px;
    }
  }

  /* @media screen and (max-width: 380px) {
   
    .rjLogo{
        visibility: hidden;
    }
  } */

  @media screen and (max-width: 991px) {
   #project-cards{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   }
    
  }

  @media screen and (max-width: 991px) {
    #Experience h1, #Contact h1{
      display: none;
    }
     
   }
